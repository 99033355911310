import React, { useEffect, useContext, useState } from "react"
import ReactModal from 'react-modal'
import "./comboWrapperProject.scss"
import { string_translation } from "../../../utils"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import SliderNavigation from '../SharedComponents/SliderNavigation'

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import { createMarkup } from "../../../utils"

export default function ComboWrapperProject(props) {

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)


  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      videos_ar:translateString(language: AR, string: "VIDEOS")
      videos_en:translateString(language: EN, string: "VIDEOS")
      gallery_ar:translateString(language: AR, string: "GALLERY")
      gallery_en:translateString(language: EN, string: "GALLERY")
      map_ar:translateString(language: AR, string: "MAP")
      map_en:translateString(language: EN, string: "MAP")
      popup_box_ar:translateString(language: AR, string: "التقديم على هذه القطعة يتطلب مراجعة الأمانة")
      popup_box_en:translateString(language: EN, string: "Applying to this Land requires referring to the Riyadh Municipality")
      amana_map_ar:translateString(language: AR, string:"البوابة المكانية")
      amana_map_en:translateString(language: EN, string: "Geoportal")
      geo_portal_heading_ar:translateString(language: AR, string:"البحث عن الدليل الخاص بقطعة الأرض")
      geo_portal_heading_en:translateString(language: EN, string: "Search for the Plot’s Design Code Manual")
      geo_portal_sub_heading_ar:translateString(language: AR, string:"يمكنك الاطلاع على الدليل الخاص بقطعة الأرض من خلال البوابة المكانية\nالخاصة بأمانة منطقة الرياض بالنقر على الأيقونة، ولمزيد من المعلومات يرجى الاطلاع على النقاط أدناه:")
      geo_portal_sub_heading_en:translateString(language: EN, string: "You can view the Plot’s Design Code Manual through the Geoportal of the Riyadh Region \nMunicipality by clicking on the icon. For more information, please see the below points: ")
      geo_portal_ar:translateString(language: AR, string:"البوابة المكانية")
      geo_portal_en:translateString(language: EN, string: "Geo Portal")
      geo_portal_btn_ar:translateString(language: AR, string:"البوابة المكانية  ←   ")
      geo_portal_btn_en:translateString(language: EN, string: "Geo Portal →")
      search_box_ar:translateString(language: AR, string:"خانة البحث")
      search_box_en:translateString(language: EN, string: "Search Box")
      search_box_data_ar:translateString(language: AR, string:"من خلال خانة البحث، قم باختيار 'إضافة معايير البحث' ومن ثم اختيار 'المخططات' من القائمة وإدخال رقم المخطط.")
      search_box_data_en:translateString(language: EN, string: "Select 'Add Search Criteria' from the search box, then 'Plans' from the list and enter the plan number.")
      plot_number_ar:translateString(language: AR, string:"رقم قطعة الأرض")
      plot_number_en:translateString(language: EN, string: "Plot Number")
      plot_number_data_ar:translateString(language: AR, string:"بتكرار العملية السابقة وبعد إدخال رقم المخطط، اضغط على 'إضافة معايير البحث' وإدراج رقم قطعة الأرض.")
      plot_number_data_en:translateString(language: EN, string: "By repeating the previous step and after entering the plan number, select 'Add Search Criteria' and enter the plot number.")
      plot_selection_ar:translateString(language: AR, string:"اختيار قطعة الأرض")
      plot_selection_en:translateString(language: EN, string: "Plot Selection")
      plot_selection_data_ar:translateString(language: AR, string:"بعد الضغط على زر 🔍 ستظهر عدة نتائج، اضغط على القطعة المراد الاستعلام عنها. يمكنك معرفة الدليل بتقريب الخريطة على قطعة الأرض حتى يظهر لك رمز الدليل.")
      plot_selection_data_en:translateString(language: EN, string: "After clicking on 🔍, several results will appear. Then, click on the plot you want to inquire about. You can find out the Manual by zooming the map on the plot until the Design Code Manual’s symbol appears.")
      design_code_manual_ar:translateString(language: AR, string:"الدليل الخاص بقطعة الأرض")
      design_code_manual_en:translateString(language: EN, string: "Design Code Manuals for Plots")
    }
  }`)
 
  

  const lineRefs = React.useRef([]);

  lineRefs.current = props.data.map((_, i) => lineRefs.current[i] ?? React.createRef());

  const shouldRenderArrows = true;


  let hash="#project-0";
  if (typeof window !== 'undefined') {
    try{
  if(sessionStorage.getItem('hashproject')){
    hash=sessionStorage.getItem('hashproject');
  }
}catch(ex){
        
}
}
  try{
  if (window.location.hash) {
    hash=window.location.hash;
  }
  }catch(err){
    
  }

  useEffect(
    () => {
      if (hash) {
         setActiveTab(hash)
      }
    }
  )

  function setActiveTab(hashurl){
    var elements = document.getElementsByClassName("listhead")[0].getElementsByTagName("li");
      for (var i = 0; i < elements.length; i++) {
      if(elements[i].dataset.tab==hashurl){
        elements[i].classList.add("active");
      }else{
        elements[i].classList.remove("active");
      }
    }


    var elementsdown = document.getElementsByClassName("listbottom")[0].getElementsByTagName("div");
  
      for (var i = 0; i < elementsdown.length; i++) {
        if(elementsdown[i].id!=""){
      if('#'+elementsdown[i].id==hashurl){
        elementsdown[i].classList.add("active");
      }else{
        elementsdown[i].classList.remove("active");
      }
    }
    }
  }
  function updatehash(hash){
    if (typeof window !== 'undefined') {
      try{
      sessionStorage.setItem('hashproject', hash);
      }catch(ex){
        
      }
    }
    
  var arabic = document.querySelectorAll(".smail-menu-item,.arabic");
    try{
     for ( let i=0 ; i < arabic.length ; i++) {
      var elem = arabic[i];
       if(elem.childNodes){
       const url = elem.childNodes[0].href;
       const urlObj = new URL(url);
        urlObj.hash = hash;
        const result = urlObj.toString();
       elem.childNodes[0].href=result;
       }

      }
    }catch(aaa){

    }
  }
  return (
    <>
      <section className="combo-wrapper">
        <div className="container">
          <div className="combo-box-tab"> 
            <ul className="tabs tabs-list h2 listhead" data-controls="#drf">
            {props.data?.map((project, index) => (
 <li data-tab={"#project-"+index} onClick={(e) => updatehash("#project-"+index)}   className={hash == "#project-"+index? 'active': '' }>
 {project.projectName}
</li>
            ))}
             
            
            </ul>
          </div>
          <div className="tab-container listbottom" id="drf">
          {props.data?.map((project, index) => (
            <div className={hash == "#project-"+index? 'active resizing': 'resizing' } id={"project-"+index}>
             
              <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination]}
                pagination={{ clickable: true }}
                id={"design-hub-img-gallery"+index}
                spaceBetween={5}
                slidesPerView={1}
                slidesPerGroup={1}
                grabCursor={"true"}
                ref={lineRefs.current[index]}
                dir={locale === "en" ? "ltr" : "rtl"}
              >
                {project?.projectImages?.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div className="img-wrapper">
                      <img src={image?.localFile?.publicURL} alt={image.title} />
                    </div>
                  </SwiperSlide> 
                ))}

                {shouldRenderArrows && <SliderNavigation ref={lineRefs.current[index]} />}

              </Swiper>
            </div>
          ))}
            
            
           
  
          </div>
        
        </div>
      </section>
    </>
  )
}
