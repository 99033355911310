import * as React from "react"
import WhoWeAre from "../components/WhoWeAre"
import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Seo from '../components/seo'
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import { useContext } from "react"
import ComboWrapperProject from "../components/ComboWrapperProject"
import GetintouchProject from "../components/GetinTouchProject"

export default function AboutProject(pageData) {
  const data = pageData?.data
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri

  return (
    <>
      <Seo data={seo}/>
      <Layout translatedURI={translatedURI}>
        <WhoWeAre
          title={data.wpPage?.title}
          name={data.wpPage.pageSubtitle?.subtitle}
          description={data.wpPage.pageDescription?.pageDescription}
        />
    
    <ComboWrapperProject data={data?.wpPage?.AboutProjectPage?.projects} locale={locale}/>
    <GetintouchProject data={data?.wpPage?.AboutProjectPage?.contactForm} locale={locale}/>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query pageData($databaseId: Int) {
    wpPage(databaseId: { eq: $databaseId }) {
      id
      title
      pageSubtitle {
        subtitle
      }
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      content
      date
      pageDescription {
        fieldGroupName
        pageDescription
      }
      AboutProjectPage {
        contactForm
        projects {
          projectName
          projectImages {
            altText
            filename
            title
            localFile {
              publicURL
            }
          }
        }
      }
      
    }
  }
`
